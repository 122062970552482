/* 
Color Palette 1 
Alice Blue: E7ECEF
Cerulean Frost: 6096BA
Baby Blue Eyes: A3CEF1
Battleship Grey: 8B8C89
Onyx: 37393A


Color Pallete 2
Light Cornflower Blue: 9DD1f1
Light Cyan: 54BCF0
Red Salsa: F15152
Onyx: 37393A
Raisin Black: 1B1B1E
*/

/* @import './cssFiles/projects.css'; */

html, body, div, span, section, h1, h2, h3, h4, h5, h6, p, a, abbr
{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color:aliceblue
}

:root {
  /* // Used when no type is provided */
  --toastify-color-progress-dark: linear-gradient(
    to right,
    #37393A,
    #A3CEF1,
    #E7ECEF 
  ) 
  !important;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

textarea {
  font-family: sans-serif;
}
::-webkit-input-placeholder {
  font-family: sans-serif;
}
:-moz-placeholder {
  font-family: sans-serif;
}
::-moz-placeholder {
  font-family: sans-serif;
}
:-ms-input-placeholder {
  font-family: sans-serif;
}

.App {
  text-align: center;
}



