
.projects-body{
    display:flex;
    flex-direction: column;
    height: 100vh;
    /* background-color: red; */
    background-image: url("../../src/assets/pictures/comp-phone-pen.jpg");
    /* height: 150%; */
    background-position: center;
    background-size:cover;
    background-repeat: none;
  }
  
  .projects-header{
    display:flex;
    width:90%;
    padding-left: 5%;
    padding-top: 3%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5%;
  
  }
  
  .projects-develop{
    color:aliceblue;
    height:45px;
    font-size: 40px;
    font-weight: bolder;
  }
  
  .header-tag{
    color:aliceblue;
    height:30px;
    /* font-weight: bold; */
    margin-top: 2%;
  }
  
  .projectLogo{
    width:10vw;
    max-width: 150px;
    min-width: 110px;
    margin-left: -10px;
    margin-top: -10px;
    /* height:100px;
    margin-left: -35px; */
  }
  
  .home-links{
    display:flex;
    justify-content: space-evenly;

  }
  
  .link-button{
    height: 30px;
    background-color: transparent;
    border-style: none;
    color: #54bcf0;
    font-weight: bolder;
    transition: 0.4s;

  }
  
  .link-button:hover{
    cursor: pointer;
  }
  
  
  .projectsList{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2.5%;
    /* min-height: 70vh; */
    max-height: 70vh;
    align-self: center;
    width:75vw;
    background-color: rgba(0, 0, 0, 0.749);
    text-align: center;
    overflow-y: auto;
    
  }

  .projectsList::-webkit-scrollbar-thumb{
    background-color: #54bcf0;
  }
  
  .content{
    /* overflow-y: auto; */
    height: calc(100% - 30px);
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;

  }
 

  .projectsTop{
    display:flex;
    justify-content: space-around;
    /* margin-top: 35vh; */
  }
  
  .projectsBottom{
    display:flex;
    justify-content: space-around;
  
  }


  
  .project{
    display:flex;
    margin-bottom: 2.5%;
    width:90%;
    justify-content: space-around;
    align-items: center;
    margin-top: 2.5vh;

  }

  .lastProject{
    display:flex;
    margin-bottom: 2.5%;
    width:90%;
    justify-content: space-around;
    align-items: center;
    margin-top: 2.5vh;
    padding-bottom: 30px;
  }

  .projectLeft{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    /* background-color: burlywood; */
  }


  .projectName{
    font-size: 28px;
    font-weight: bold;
  }
  
  .projectVideo{
    margin-top: 20px;
    margin-bottom: 20px;
    height:283px;
    width: 504px;
  }

  .projectRight{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:40%;
    /* background-color: burlywood; */
  }
  
  
  .projectDescription{
    font-size: 22px;
    width:100%;
  }
  
  .repoUrl{
    color:#54bcf0;
    font-size: 20px;
  }

  .techIntro{
    font-size: 22px;
  }
  
  .projectTechs{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    /* background-color: aqua; */
    min-width: fit-content;

  
  }
  
  .techImage{
    max-width: 50px;
    max-height:50px;
  }
  
  /* RESPONSIVE SECTION */


  @media(max-width: 1300px){

    .projectLogo{
      min-width:110px;
      margin-left: -10px;
      margin-top: -10px;
      /* height:100px;
      margin-left: -35px; */
    }

    .home-links{
      width: 50%;
    }

    .projectsList{
      margin-top: 3.5%;
      width:82.5vw;
      max-height: 70vh;
    }

    .projectDescription{
      font-size: 16px;
    }

    .projectVideo{
      width: 378px;
      height: 207px;
    }

    .techIntro{
      font-size: 18px;
    }

    .techImage{
      max-width: 40px;
      max-height: 40px;
    }

  }

  @media (max-width:990px){
    .projects-develop{
      font-size: 32px;
    }

    .home-links{
      width:fit-content;
    }

    .projectsList{
      max-height: 80vh;
      margin-top: 4%;
    }

    .projectName{
      font-size: 24px;
    }
    .projectDescription{
      font-size: 16px;
    }

    .projectVideo{
      width: 306px;
      height: 168px;
    }

    .techIntro{
      font-size: 18px;
    }

    .techImage{
      max-width: 40px;
      max-height: 40px;
    }
  }

  @media (max-width: 850px){
    .projectsList{
      max-height: 80vh;
      margin-top: 15%;
    }


    .project{
      flex-direction: column;
    }

    .lastProject{
      flex-direction: column;
    }

    .projectName{
      font-size: 28px;
    }

    .projectDescription{
      width:200%;
      margin-top: -15%;
    }

    .repoUrl{
      font-size: 18px;

    }

    .repoSection{
      width:200%;
    }

    .techImage{
      max-width: 40px;
      max-height: 40px;
      margin-bottom: 5%;
    }
  }