.home-body{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    /* background-color: red; */
    background-image: url("../../src/assets/pictures/comp-phone-pen.jpg");
    /* height: 150%; */
    background-position: center;
    background-size:cover;
    background-repeat: none;
  }
  
@font-face {
  font-family: 'ideoma';
  src: url(../assets/fonts/intersect.c-brk.ttf) format('opentype');
}

  .home-header{
    display:flex;
    width:90%;
    padding-left: 5%;
    padding-top: 3%;
    justify-content: space-between;
    align-items: center;
  
  }
  
  .home-develop{
    color:aliceblue;
    height:45px;
    font-size: 40px;
    font-weight: bolder;

  }
  
  .mainLogo{
    width: 10vw;
    max-width: 150px;
    min-width: 110px;
    margin-left: -10px;
    margin-top: -10px;
    /* height:100px;
    margin-left: -35px; */
  }


  .header-tag{
    color:aliceblue;
    height:30px;
    font-size: 24px;
    margin-top: 2%;
    font-family: sans-serif;

  }
  
  
  .home-links{
    display:flex;
    justify-content: space-evenly;
    width: 35%;
    /* background-color: turquoise; */
  }
  
  .link-button{
    height: 30px;
    background-color: transparent;
    border-style: none;
    /* color: #6096BA; */
    color: #54bcf0;
    font-size: 26px;
    font-weight: bolder;
    transition: 0.4s;
  
  }
  
  .link-button:hover{
    cursor: pointer;
  }


  .link-button:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: relative;
    background: #54bcf0;
    color: #54bcf0;
    transition: width 0.2s ease 0s, left 0.2s ease 0s;
    width: 0;
  }
  
  .link-button:hover:after {
    width: 100%; 
    left: 0;
  }
  
  .home-hero{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 5%;
    max-width: 40%;
    height: 30%;
  }
  
  .hero-tombstone{
    font-size: 74px;
    font-weight: bold;
    color: #54bcf0
  }
  
  .hero-description{
    margin-left: 5%;
    padding-bottom: 5%;
    max-width: 40%;
    font-size: 22px;
  }

  @media(max-width: 1300px){

    .home-hero{
      margin-top: 65px;
    }

    .hero-tombstone{
      font-size: 64px;

    }


  }
  
  @media (max-width: 992px){
    
    .link-button{
      font-size: 22px;
    }
  }
  
  @media (max-width: 768px){
    
    .home-develop{
      font-size: 34px;
    }

    .header-tag{
      font-size: 20px;
    }

    .home-links{
      width: fit-content;
    }

    .link-button{
      font-size: 22px;
    }

    .hero-description{
      max-width: 65%;
    }
  }