
.contact-body{
    display:flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 100vh;
    /* background-color: red; */
    background-image: url("../../src/assets/pictures/comp-phone-pen.jpg");
    /* height: 150%; */
    background-position: center;
    background-size:cover;
    background-repeat: none;
  }
  
  .contact-header{
    display:flex;
    width:90%;
    padding-left: 5%;
    padding-top: 3%;
    justify-content: space-between;
    align-items: center;
  
  }

  .contact-develop{
    color:aliceblue;
    height:45px;
    font-size: 40px;
    font-weight: bolder;
  }

  .contactLogo{
    width: 10vw;
    max-width: 150px;
    min-width: 110px;
    margin-left: -10px;
    margin-top: -10px;
    /* height:100px;
    margin-left: -35px; */
  }
  
  .contactFull{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position:absolute 50%; 
    transform: translateY(15%);
    /* transform: translateX(-10%); */
    width: 40%;
    padding-top: 2.5%;
    justify-self: center;
    height: 60%;
    margin-left:7.5%;
    /* background-color: #8b8c8979; */
    border-radius: 6px;
    
  }
  
  .contactGreeter{
    width: 70%;
    font-size: 18px;
    text-align: center;
    /* background-color: thistle; */
  }
  
  .contact-form{
  display:flex;
  flex-direction: column;
  align-items: center;
  width:70%;
  /* background-color: aqua; */
  gap: 20px;
  /* padding-bottom: 30%; */
  }
  
  .name-input{
    height:22px;
    border-radius: 6px;
    border-style: solid;
    border-width: 2px;
    border-color: #8B8C89;
    color:#8B8C89;
    font-size: 16px;
    background-color: transparent;
    width:90%
  }
  
  .name-input::placeholder{
    color:#8B8C89;
    font-size: 16px;
  }
  
  .email-input{
    height:22px;
    border-radius: 6px;
    border-style: solid;
    border-width: 2px;
    border-color: #8B8C89;
    color:#8B8C89;
    font-size: 16px;
    background-color: transparent;
    width:90%
  }
  
  .email-input::placeholder{
    color:#8B8C89;
    font-size: 16px;
  }
  
  .message-input{
    border-radius: 6px;
    border-style: solid;
    border-width: 2px;
    border-color: #8B8C89;
    color:#8B8C89;
    font-size: 16px;
    background-color: transparent;
    width:90%;
    height:130px;
  
  }
  
  .message-input::placeholder{
    color:#8B8C89;
    font-size: 16px;
    padding-top: 1px;
  }
  
  
  
  .submit-button{
    width: fit-content;
    height:fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    align-self: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color:#54bcf0;
    border-radius: 6px;
    margin-bottom: -5%;
  }
  
  .connection-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    padding-bottom: 5%;
    margin-bottom: 5%;
  }
  
  .connection-prompt{
    font-size: 20px;
    /* margin-bottom: 15px; */
  }
  
  .connection-icons{
    display: flex;
    width: 120%;
    justify-content: space-between;
  }
  
  .logo{
    height: 50px;
   }
  
  .logo:hover{
    cursor:pointer;
  }
  
  .custom-toast{
    color:black;
    font-size: 25px;
    font-weight: bold;
  }

  /* @media (max-width:1300px){

    .contactLogo{
      height:110px;
    }


  } */
  
  @media(max-width:992px){
    
    /* .contactLogo{
      height: 125px;
    } */
    .contactFull{
      width: 50%;
      height:62.5%;
    }

  }

  @media (max-width:768px){
    
    .contactFull{
      /* align-self: center; */
      width: 65%;
      margin-left: 0%;
      margin-top: 5%;
    }

    /* .logo{
      height:55px;
    } */
  }

  @media(max-width:576px){

    .contact-develop{
      font-size: 32px;
    }

    .contactFull{
      width:80%;
      height:70%;
      margin-left: 10%;
    }
  }