.about-body{
  display:flex;
  flex-direction: column;
  /* justify-content: space-evnly; */
  height: 100vh;
  /* background-color: red; */
  background-image: url("../../src/assets/pictures/comp-phone-pen.jpg");
  /* height: 150%; */
  background-position: center;
  background-size:cover;
  background-repeat: none;
}

.aboutLogo{
  width: 10vw;
  max-width: 150px;
  min-width: 110px;
  margin-left: -10px;
  margin-top: -10px;
  /* height:100px;
  margin-left: -35px; */
}

.about-me{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width:40%;
    height:80%;
    margin-left: 7.5%;
    margin-bottom: 2.5%;
    padding-top: 2.5%;
    /* margin-top: -15%; */
    /* background-color: royalblue; */
  }
  
  .about-text{
    /* margin-top: 7.5%; */
    width: 90%;
    font-size: 18px;
    text-align: center;
  }
  
  .profilePic{
    height:55vh;
  }
  
  @media(max-width:1300px){
/* 
    .aboutLogo{
      height:110px;
    } */

    .profilePic{
      height: 55vh;
      margin-top: 10%;
    }
  }

  @media(max-width:1200px){
    .profilePic{
      height: 45vh;
    }
  }
  @media(max-width:992px){

    .about-me{
      padding-top:0%;
      height:80%;
      margin-top: 5vh;
      /* background-color: lavender; */
    }
    
    .about-text{
      font-size: 18px;
      width: 100%;
    }

    .profilePic{
      height:50vh
    }
  
  }

  @media (max-width:850px){

    .about-me{
      margin-left: 0%;
      align-self: center;
    }

    .about-text{
      font-size: 18px;
      width: 150%;
    }
    
    .profilePic{
      height:auto;
      width:60vw;
    }
  }